import { useState, ChangeEvent, useEffect } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import ImageUpload from '../common/ImageUpload';
import {
  ColorFormType,
  ProductFormType,
  SizeFormType,
} from '../../containers/product';
import { Close } from '@mui/icons-material';

type ProductProps = {
  product_list: any[];
  category_list: any[];
  prevImage: string[];
  image: File[];
  setImage: (state: File[]) => void;
  colorImage: File[];
  setColorImage: (state: File[]) => void;
  sizeList: any[];
  colorList: any[];
  categoryList: any[];
  form: ProductFormType;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  sizeForm: SizeFormType;
  onChangeSize: (event: ChangeEvent<HTMLInputElement>) => void;
  colorForm: ColorFormType;
  onChangeColor: (event: ChangeEvent<HTMLInputElement>) => void;
  selectMeasurement: string;
  setSelectMeasurement: (state: string) => void;
  handleSize: () => void;
  handleColor: () => void;
  handleCategory: (category: any, isCategory: boolean) => void;
  handleCreateProduct: (
    setIsModal: (state: boolean) => void,
    isDye: boolean
  ) => void;
  handleUpdateStatus: (id: string, status: string) => void;
  handleDetail: (product: any) => void;
  handleReset: () => void;
  handleDeleteSize: (id: number) => void;
  handleDeleteColor: (id: number) => void;
  handleUpdateProduct: (
    setIsModal: (state: boolean) => void,
    product: any
  ) => void;
  handleDeleteProduct: (setIsModal: (state: boolean) => void) => void;
};

export default function Product({
  product_list,
  category_list,
  prevImage,
  image,
  setImage,
  colorImage,
  setColorImage,
  sizeList,
  colorList,
  categoryList,
  form,
  onChange,
  sizeForm,
  onChangeSize,
  colorForm,
  onChangeColor,
  selectMeasurement,
  setSelectMeasurement,
  handleSize,
  handleCategory,
  handleCreateProduct,
  handleUpdateStatus,
  handleDetail,
  handleReset,
  handleDeleteSize,
  handleUpdateProduct,
  handleDeleteProduct,
  handleColor,
  handleDeleteColor,
}: ProductProps) {
  const [isModal, setIsModal] = useState(false);
  const [isDye, setIsDye] = useState(false);
  const [isModal2, setIsModal2] = useState(false);
  const [product, setProduct] = useState<any>(null);

  const handleCategoryColor = (category: any) => {
    if (category.menu) return '#1c7ed6';

    if (category.sub_menu) return '#f783ac';
  };

  useEffect(() => {
    if (!isModal) {
      handleReset();
      setIsDye(false);
    }
  }, [isModal]);

  useEffect(() => {
    if (!isModal2) {
      handleReset();
      setIsDye(false);
    }
  }, [isModal2]);

  return (
    <Box>
      <div className={cn('title_wrap')}>
        <span className={cn('title_wrap-span')}>Product</span>

        <Button
          variant='contained'
          style={{ margin: '0 30px 0 auto' }}
          onClick={() => {
            setIsModal(true);
          }}
        >
          Create Product
        </Button>

        <Button
          variant='contained'
          style={{ margin: '0' }}
          onClick={() => {
            setIsModal(true);
            setIsDye(true);
          }}
        >
          Create Dye Product
        </Button>
      </div>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell align='center'>ID</TableCell>
              <TableCell align='center'>Name</TableCell>
              <TableCell align='center'>Brand</TableCell>
              <TableCell align='center'>Details</TableCell>
              <TableCell align='center'>Type</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {product_list.map((product: any, index: number) => (
              <TableRow
                key={index}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  cursor: 'pointer',
                }}
              >
                <TableCell
                  component='th'
                  scope='row'
                  align='center'
                  onClick={() => {
                    handleDetail(product);
                    setIsModal2(true);
                    setProduct(product);
                  }}
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  align='center'
                  onClick={() => {
                    handleDetail(product);
                    setIsModal2(true);
                    setProduct(product);
                  }}
                >
                  {product.name}
                </TableCell>
                <TableCell
                  align='center'
                  onClick={() => {
                    handleDetail(product);
                    setIsModal2(true);
                    setProduct(product);
                  }}
                >
                  {product.brand.name}
                </TableCell>
                <TableCell
                  align='center'
                  onClick={() => {
                    handleDetail(product);
                    setIsModal2(true);
                    setProduct(product);
                  }}
                >
                  {product.details}
                </TableCell>
                <TableCell align='center'>
                  <FormControl style={{ width: '120px' }}>
                    <InputLabel id='demo-simple-select-label'>Type</InputLabel>
                    <Select
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      defaultValue={product.type}
                      label='Type'
                      onChange={(e) =>
                        handleUpdateStatus(product.id, e.target.value)
                      }
                    >
                      <MenuItem value={'sale'}>Sale</MenuItem>
                      <MenuItem value={'new'}>New</MenuItem>
                      <MenuItem value={'best'}>Best</MenuItem>
                      <MenuItem value={'none'}>None</MenuItem>
                      <MenuItem value={'disabled'}>Disabled</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={isModal}
        onClose={() => {
          setIsModal(false);
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <ModalBox>
          <div className={cn('list')}>
            <TextField
              id='standard-basic'
              label='Name *'
              variant='outlined'
              name='name'
              value={form.name}
              onChange={onChange}
              style={{ marginBottom: '30px' }}
            />

            <TextField
              id='standard-basic'
              label='Brand *'
              variant='outlined'
              name='brand'
              value={form.brand}
              onChange={onChange}
              style={{ marginBottom: '30px' }}
            />

            <TextField
              id='standard-basic'
              multiline
              rows={10}
              label='Details *'
              variant='outlined'
              name='details'
              value={form.details}
              onChange={onChange}
              style={{ marginBottom: '30px' }}
            />

            <TextField
              id='standard-basic'
              label='Guam Original Price *'
              variant='outlined'
              style={{ marginBottom: '30px' }}
              name='guam_original_price'
              value={form.guam_original_price}
              onChange={onChange}
            />

            <TextField
              id='standard-basic'
              label='Saipan Original Price *'
              variant='outlined'
              style={{ marginBottom: '30px' }}
              name='saipan_original_price'
              value={form.saipan_original_price}
              onChange={onChange}
            />

            <TextField
              id='standard-basic'
              label='Red Text'
              variant='outlined'
              style={{ marginBottom: '30px' }}
              name='red'
              value={form.red}
              onChange={onChange}
            />

            <TextField
              id='standard-basic'
              label='Orange Text'
              variant='outlined'
              style={{ marginBottom: '30px' }}
              name='orange'
              value={form.orange}
              onChange={onChange}
            />

            {isDye ? (
              <>
                <span className={cn('list-title')}>Color List</span>

                <ImageUpload
                  images={colorImage}
                  setImages={setColorImage}
                  multiple
                  style={{
                    width: '100%',
                    margin: '0 auto 30px auto',
                    justifyContent: 'space-between',
                  }}
                />

                <TextField
                  id='standard-basic'
                  label='Name *'
                  variant='outlined'
                  style={{ marginBottom: '10px' }}
                  name='name'
                  value={colorForm.name}
                  onChange={onChangeColor}
                />

                <TextField
                  id='standard-basic'
                  label='Code *'
                  variant='outlined'
                  style={{ marginBottom: '10px' }}
                  name='code'
                  value={colorForm.code}
                  onChange={onChangeColor}
                />

                <Button
                  variant='outlined'
                  onClick={handleColor}
                  size='medium'
                  style={{ marginBottom: '10px' }}
                >
                  Add Color
                </Button>

                <div className={cn('list-size_list')}>
                  <div className={cn('list-size_list-wrap')}>
                    {colorList.map((data: any, index: number) => {
                      return (
                        <span
                          key={index}
                          className={cn('list-size_list-wrap-item')}
                        >
                          {`name: ${data.name} | code: ${data.code}`}
                        </span>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            <span className={cn('list-title')}>Size List</span>

            <TextField
              id='standard-basic'
              label='Size *'
              variant='outlined'
              style={{ marginBottom: '10px' }}
              name='size'
              value={sizeForm.size}
              onChange={onChangeSize}
            />

            <FormControl style={{ width: '100%', marginBottom: '10px' }}>
              <InputLabel id='demo-simple-select-label'>Measurement</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={selectMeasurement}
                label='Type'
                onChange={(e) => setSelectMeasurement(e.target.value)}
              >
                <MenuItem value={'ml'}>ML</MenuItem>
                <MenuItem value={'floz'}>FLOZ</MenuItem>
              </Select>
            </FormControl>

            <TextField
              id='standard-basic'
              label='Guam Quantity Price *'
              variant='outlined'
              style={{ marginBottom: '10px' }}
              name='guam_quantity_price'
              value={sizeForm.guam_quantity_price}
              onChange={onChangeSize}
            />

            <TextField
              id='standard-basic'
              label='Guam Discount Percent *'
              variant='outlined'
              style={{ marginBottom: '10px' }}
              name='guam_discount_percent'
              value={sizeForm.guam_discount_percent}
              onChange={onChangeSize}
            />

            <TextField
              id='standard-basic'
              label='Saipan Quantity Price *'
              variant='outlined'
              style={{ marginBottom: '10px' }}
              name='saipan_quantity_price'
              value={sizeForm.saipan_quantity_price}
              onChange={onChangeSize}
            />

            <TextField
              id='standard-basic'
              label='Saipan Discount Percent *'
              variant='outlined'
              style={{ marginBottom: '10px' }}
              name='saipan_discount_percent'
              value={sizeForm.saipan_discount_percent}
              onChange={onChangeSize}
            />

            <Button
              variant='outlined'
              onClick={handleSize}
              size='medium'
              style={{ marginBottom: '10px' }}
            >
              Add Size
            </Button>

            <div className={cn('list-size_list')}>
              <div className={cn('list-size_list-wrap')}>
                {sizeList.map((data: any, index: number) => {
                  return (
                    <span
                      key={index}
                      className={cn('list-size_list-wrap-item')}
                    >
                      {data.measurement === 'ml'
                        ? `${parseInt(
                            data.size
                          )} ml | quantity [guam/saipan]: ${
                            data.guam_quantity_price
                          }/${
                            data.saipan_quantity_price
                          } | discount percent [guam/saipan]: ${
                            data.guam_discount_percent
                          }/${data.saipan_discount_percent}`
                        : `${data.size} fl oz | quantity [guam/saipan]: ${data.guam_quantity_price}/${data.saipan_quantity_price} | discount percent [guam/saipan]: ${data.guam_discount_percent}/${data.saipan_discount_percent}`}
                    </span>
                  );
                })}
              </div>
            </div>

            <ImageUpload
              images={image}
              setImages={setImage}
              multiple
              style={{
                width: '100%',
                margin: '0 auto 30px auto',
                justifyContent: 'space-between',
              }}
            />

            <span className={cn('list-title')}>
              Category List (Menu: Blue, Sub Menu: Pink)
            </span>

            <div className={cn('list-category_list')}>
              <div className={cn('list-category_list-wrap')}>
                {category_list.map((category: any, index: number) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          onClick={(e: any) =>
                            handleCategory(category, e.target.checked)
                          }
                        />
                      }
                      label={
                        <span
                          style={{
                            color: handleCategoryColor(category),
                            userSelect: 'none',
                          }}
                        >
                          {`${category.title}${
                            category.sub_menu
                              ? ` / sub menu (${category.sub_menu})`
                              : ''
                          }`}
                        </span>
                      }
                    />
                  );
                })}
              </div>
            </div>

            <Button
              variant='contained'
              onClick={() => handleCreateProduct(setIsModal, isDye)}
              size='large'
              style={{ marginTop: '50px' }}
            >
              Create
            </Button>
          </div>
        </ModalBox>
      </Modal>

      <Modal
        open={isModal2}
        onClose={() => {
          setIsModal2(false);
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <ModalBox>
          <div className={cn('list')}>
            <TextField
              id='standard-basic'
              label='Name *'
              variant='outlined'
              name='name'
              value={form.name}
              onChange={onChange}
              style={{ marginBottom: '30px' }}
            />

            <TextField
              id='standard-basic'
              label='Brand *'
              variant='outlined'
              name='brand'
              value={form.brand}
              onChange={onChange}
              style={{ marginBottom: '30px' }}
            />

            <TextField
              id='standard-basic'
              multiline
              rows={10}
              label='Details *'
              variant='outlined'
              name='details'
              value={form.details}
              onChange={onChange}
              style={{ marginBottom: '30px' }}
            />

            <TextField
              id='standard-basic'
              label='Guam Original Price *'
              variant='outlined'
              style={{ marginBottom: '30px' }}
              name='guam_original_price'
              value={form.guam_original_price}
              onChange={onChange}
            />

            <TextField
              id='standard-basic'
              label='Saipan Original Price *'
              variant='outlined'
              style={{ marginBottom: '30px' }}
              name='saipan_original_price'
              value={form.saipan_original_price}
              onChange={onChange}
            />

            <TextField
              id='standard-basic'
              label='Red Text'
              variant='outlined'
              style={{ marginBottom: '30px' }}
              name='red'
              value={form.red}
              onChange={onChange}
            />

            <TextField
              id='standard-basic'
              label='Orange Text'
              variant='outlined'
              style={{ marginBottom: '30px' }}
              name='orange'
              value={form.orange}
              onChange={onChange}
            />

            {product && product.product_type === 'dye' ? (
              <>
                <span className={cn('list-title')}>
                  Current Color Image List (Click to see Image)
                </span>

                <div className={cn('list-image_list')}>
                  {product.color_list.map((color: any, index: number) => {
                    return (
                      <span
                        className={cn('list-image_list-item')}
                        key={index}
                        onClick={() =>
                          window.open(
                            `https://system4u.s3.ap-northeast-2.amazonaws.com/production/product/${product.id}/color/${color.name}.jpeg`,
                            '_blank'
                          )
                        }
                      >
                        {color.name}.jpeg
                      </span>
                    );
                  })}
                </div>

                <span className={cn('list-title')}>Color List</span>

                <ImageUpload
                  images={colorImage}
                  setImages={setColorImage}
                  multiple
                  style={{
                    width: '100%',
                    margin: '0 auto 30px auto',
                    justifyContent: 'space-between',
                  }}
                />

                <TextField
                  id='standard-basic'
                  label='Name *'
                  variant='outlined'
                  style={{ marginBottom: '10px' }}
                  name='name'
                  value={colorForm.name}
                  onChange={onChangeColor}
                />

                <TextField
                  id='standard-basic'
                  label='Code *'
                  variant='outlined'
                  style={{ marginBottom: '10px' }}
                  name='code'
                  value={colorForm.code}
                  onChange={onChangeColor}
                />

                <Button
                  variant='outlined'
                  onClick={handleColor}
                  size='medium'
                  style={{ marginBottom: '10px' }}
                >
                  Add Color
                </Button>

                <div className={cn('list-size_list')}>
                  <div className={cn('list-size_list-wrap')}>
                    {colorList.map((data: any, index: number) => {
                      if (data.delete) return <></>;

                      return (
                        <div
                          key={index}
                          className={cn('list-size_list-wrap-item')}
                          style={{
                            display: 'flex',
                          }}
                        >
                          {`name: ${data.name} | code: ${data.code}`}

                          <Close
                            style={{
                              width: 'auto',
                              height: '20px',
                              margin: 'auto 0 auto auto',
                              cursor: 'pointer',
                            }}
                            onClick={() => handleDeleteColor(data.id)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            <span className={cn('list-title')}>Size List</span>

            <TextField
              id='standard-basic'
              label='Size *'
              variant='outlined'
              style={{ marginBottom: '10px' }}
              name='size'
              value={sizeForm.size}
              onChange={onChangeSize}
            />

            <FormControl style={{ width: '100%', marginBottom: '10px' }}>
              <InputLabel id='demo-simple-select-label'>Measurement</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={selectMeasurement}
                label='Type'
                onChange={(e) => setSelectMeasurement(e.target.value)}
              >
                <MenuItem value={'ml'}>ML</MenuItem>
                <MenuItem value={'floz'}>FLOZ</MenuItem>
              </Select>
            </FormControl>

            <TextField
              id='standard-basic'
              label='Guam Quantity Price *'
              variant='outlined'
              style={{ marginBottom: '10px' }}
              name='guam_quantity_price'
              value={sizeForm.guam_quantity_price}
              onChange={onChangeSize}
            />

            <TextField
              id='standard-basic'
              label='Guam Discount Percent *'
              variant='outlined'
              style={{ marginBottom: '10px' }}
              name='guam_discount_percent'
              value={sizeForm.guam_discount_percent}
              onChange={onChangeSize}
            />

            <TextField
              id='standard-basic'
              label='Saipan Quantity Price *'
              variant='outlined'
              style={{ marginBottom: '10px' }}
              name='saipan_quantity_price'
              value={sizeForm.saipan_quantity_price}
              onChange={onChangeSize}
            />

            <TextField
              id='standard-basic'
              label='Saipan Discount Percent *'
              variant='outlined'
              style={{ marginBottom: '10px' }}
              name='saipan_discount_percent'
              value={sizeForm.saipan_discount_percent}
              onChange={onChangeSize}
            />

            <Button
              variant='outlined'
              onClick={handleSize}
              size='medium'
              style={{ marginBottom: '10px' }}
            >
              Add Size
            </Button>

            <div className={cn('list-size_list')}>
              <div className={cn('list-size_list-wrap')}>
                {sizeList.map((data: any, index: number) => {
                  if (data.delete) return <></>;

                  return (
                    <div
                      key={index}
                      className={cn('list-size_list-wrap-item')}
                      style={{
                        display: 'flex',
                      }}
                    >
                      {data.measurement === 'ml'
                        ? `${parseInt(
                            data.size
                          )} ml | quantity [guam/saipan]: ${
                            data.guam_quantity_price
                          }/${
                            data.saipan_quantity_price
                          } | discount percent [guam/saipan]: ${
                            data.guam_discount_percent
                          }/${data.saipan_discount_percent}`
                        : `${data.size} fl oz | quantity [guam/saipan]: ${data.guam_quantity_price}/${data.saipan_quantity_price} | discount percent [guam/saipan]: ${data.guam_discount_percent}/${data.saipan_discount_percent}`}

                      <Close
                        style={{
                          width: 'auto',
                          height: '20px',
                          margin: 'auto 0 auto auto',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleDeleteSize(data.id)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            <span className={cn('list-title')}>
              Current Image List (Click to see Image)
            </span>

            <div className={cn('list-image_list')}>
              {prevImage.map((image: any, index: number) => {
                return (
                  <span
                    className={cn('list-image_list-item')}
                    key={index}
                    onClick={() => window.open(image.image, '_blank')}
                  >
                    Image No.{index + 1}
                  </span>
                );
              })}
            </div>

            <ImageUpload
              images={image}
              setImages={setImage}
              multiple
              style={{
                width: '100%',
                margin: '0 auto 30px auto',
                justifyContent: 'space-between',
              }}
            />

            <span className={cn('list-title')}>
              Category List (Menu: Blue, Sub Menu: Pink)
            </span>

            <div className={cn('list-category_list')}>
              <div className={cn('list-category_list-wrap')}>
                {category_list.map((category: any, index: number) => {
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          defaultChecked={categoryList.find(
                            (c) => c.id === category.id
                          )}
                          onClick={(e: any) =>
                            handleCategory(category, e.target.checked)
                          }
                        />
                      }
                      label={
                        <span
                          style={{
                            color: handleCategoryColor(category),
                            userSelect: 'none',
                          }}
                        >
                          {`${category.title}${
                            category.sub_menu
                              ? ` / sub menu (${category.sub_menu})`
                              : ''
                          }`}
                        </span>
                      }
                    />
                  );
                })}
              </div>
            </div>

            <Button
              variant='contained'
              onClick={() => handleUpdateProduct(setIsModal2, product)}
              size='large'
              style={{ marginTop: '50px' }}
            >
              Update
            </Button>

            <Button
              variant='contained'
              color='error'
              onClick={() => handleDeleteProduct(setIsModal2)}
              size='medium'
              style={{ marginTop: '30px' }}
            >
              Delete
            </Button>
          </div>
        </ModalBox>
      </Modal>
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  min-height: 100%;

  .title_wrap {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-bottom: 50px;

    &-span {
      font-size: 28px;
      font-weight: 600;
      user-select: none;
    }
  }
`;

const ModalBox = styled.div`
  width: 900px;
  height: 700px;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .list {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 50px;
    overflow: auto;

    &-title {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    &-category_list {
      width: 100%;
      height: 300px;

      &-wrap {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: auto;
      }
    }

    &-size_list {
      width: 100%;
      min-height: 150px;
      margin-bottom: 30px;

      &-wrap {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: auto;

        &-item {
          width: 100%;
          height: fit-content;
          padding: 10px;
          border-radius: 5px;
          margin-bottom: 5px;
          background-color: #fff3bf;
          font-weight: 500;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &-image_list {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;

      &-item {
        width: 100%;
        height: fit-content;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 5px;
        background-color: #d0ebff;
        font-weight: 500;
        cursor: pointer;
        user-select: none;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;
