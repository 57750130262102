import { useHistory } from 'react-router';
import Component from '../../components/sign';
import { useToasts } from 'react-toast-notifications';
import { useDispatch } from 'react-redux';
import useInputs from '../../hooks/useInputs';
import crypto from 'crypto';
import { handleSignInAxiosAPI } from '../../redux/api';
import tokenLib from '../../library/token.lib';
import { handleSignInRequest } from '../../redux/action/admin.action';

const initialForm = {
  email: '',
  password: '',
};

export type FormType = typeof initialForm;

export default function Sign() {
  const history = useHistory();

  const { addToast } = useToasts();

  const dispatch = useDispatch();

  const [form, onChange] = useInputs(initialForm);

  const handleSignIn = () => {
    if (form.email === '' || form.password === '') {
      addToast('Please enter your email or password.', {
        appearance: 'warning',
      });

      return;
    }

    function success(response: any) {
      tokenLib.setAccessToken(
        response.accessToken,
        response.access_level,
        response.country
      );
      tokenLib.setRefreshToken(response.refreshToken);

      handleSignInAxiosAPI(response.accessToken);

      history.push('/');
    }

    function failure(error: any) {
      const { message } = error;

      addToast(message, {
        appearance: 'error',
      });
    }

    dispatch(
      handleSignInRequest(
        {
          email: form.email,
          password: crypto
            .createHash('sha512')
            .update(form.password)
            .digest('hex'),
        },
        success,
        failure
      )
    );
  };

  return (
    <Component form={form} onChange={onChange} handleSignIn={handleSignIn} />
  );
}
