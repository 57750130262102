import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Component from '../../components/main';
import {
  handlUserDeleteRequest,
  handleUserListRequest,
  handleUserUpdateRequest,
} from '../../redux/action/admin.action';
import { RootState } from '../../redux/reducer';
import { useToasts } from 'react-toast-notifications';

export default function Main() {
  const { addToast } = useToasts();

  const dispatch = useDispatch();
  const { user_list } = useSelector(({ admin }: RootState) => admin);

  const handleUpdateType = (id: string, value: string) => {
    function success() {
      addToast('success update customer type', { appearance: 'success' });

      function success() {}
      function failure() {}

      dispatch(handleUserListRequest(success, failure));
    }
    function failure() {}

    dispatch(
      handleUserUpdateRequest({ id, customer_type: value }, success, failure)
    );
  };

  const handleUpdateLevel = (id: string, value: string) => {
    function success() {
      addToast('success update access level', { appearance: 'success' });

      function success() {}
      function failure() {}

      dispatch(handleUserListRequest(success, failure));
    }
    function failure() {}

    dispatch(
      handleUserUpdateRequest({ id, access_level: value }, success, failure)
    );
  };

  const handleDelete = (id: string) => {
    function success() {
      addToast('success delete user', { appearance: 'success' });

      function success() {}
      function failure() {}

      dispatch(handleUserListRequest(success, failure));
    }
    function failure() {}

    dispatch(handlUserDeleteRequest({ id }, success, failure));
  };

  useEffect(() => {
    function success() {}
    function failure() {}

    dispatch(handleUserListRequest(success, failure));
  }, []);

  return (
    <Component
      user_list={user_list}
      handleUpdateLevel={handleUpdateLevel}
      handleUpdateType={handleUpdateType}
      handleDelete={handleDelete}
    />
  );
}
