import { Fragment } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

type inquiryProps = {
  list: any[];
};

export default function Inquiry({ list }: inquiryProps) {
  const handleSentence = (text: string) => {
    return text
      .split('\n')
      .map((text: string, index: number, list: string[]) => {
        if (index === list.length - 1) {
          return text;
        }

        return (
          <Fragment key={index}>
            {text}
            <br />
          </Fragment>
        );
      });
  };

  return (
    <Box>
      <div className={cn('title_wrap')}>
        <span className={cn('title_wrap-span')}>Inquiry</span>
      </div>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell align='center'>ID</TableCell>
              <TableCell align='center'>Cellphone</TableCell>
              <TableCell align='center'>Email</TableCell>
              <TableCell align='center'>Inquiry</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {list.map((data: any, index: number) => {
              return (
                <TableRow
                  key={index}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    cursor: 'pointer',
                  }}
                >
                  <TableCell component='th' scope='row' align='center'>
                    {data.id}
                  </TableCell>

                  <TableCell component='th' scope='row' align='center'>
                    {data.cellphone}
                  </TableCell>

                  <TableCell component='th' scope='row' align='center'>
                    {data.email}
                  </TableCell>

                  <TableCell component='th' scope='row' align='center'>
                    {handleSentence(data.inquiry)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  min-height: 100%;

  .title_wrap {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    margin-bottom: 50px;

    &-span {
      font-size: 28px;
      font-weight: 600;
      user-select: none;
    }
  }
`;
