import { useState } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import {
  Avatar,
  Button,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Modal,
  Switch,
  TextField,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import ImageUpload from '../common/ImageUpload';

type BNProps = {
  notice: any;
  handleUpdateNotice: (id: number, isNotice: boolean, text: string) => void;
  banner_list: any[];
  handleDeleteBanner: (id: number, image: string) => void;
  image: File[];
  setImage: (state: File[]) => void;
  handleCreateBanner: (setIsModal: (state: boolean) => void) => void;
};

export default function BN({
  notice,
  handleUpdateNotice,
  banner_list,
  handleDeleteBanner,
  image,
  setImage,
  handleCreateBanner,
}: BNProps) {
  const [isModal, setIsModal] = useState(false);

  return (
    <Box>
      <div className={cn('title_wrap')}>
        <span className={cn('title_wrap-span')}>Banner & Notice</span>

        <Button variant='contained' onClick={() => setIsModal(true)}>
          Create Banner
        </Button>
      </div>

      {notice ? (
        <div className={cn('notice_wrap')}>
          <TextField
            id='outlined-basic'
            label='Notice'
            variant='outlined'
            defaultValue={notice.text}
            style={{ width: '600px', marginRight: '30px' }}
            onBlur={(e) =>
              handleUpdateNotice(notice.id, notice.status, e.target.value)
            }
          />

          <FormControlLabel
            control={
              <Switch
                defaultChecked={notice.status}
                onChange={(e) =>
                  handleUpdateNotice(notice.id, e.target.checked, notice.text)
                }
              />
            }
            label='Notice On/Off'
          />
        </div>
      ) : (
        <></>
      )}

      <List>
        {banner_list.map((banner: any, index: number) => {
          return (
            <ListItem
              key={index}
              secondaryAction={
                <IconButton
                  edge='end'
                  aria-label='delete'
                  onClick={() => handleDeleteBanner(banner.id, banner.image)}
                >
                  <Delete />
                </IconButton>
              }
            >
              <ListItemAvatar
                onClick={() => window.open(banner.image, '_blank')}
              >
                <Avatar src={banner.image} />
              </ListItemAvatar>

              <ListItemText
                primary={`Banner ${banner.id}`}
                onClick={() => window.open(banner.image, '_blank')}
              />
            </ListItem>
          );
        })}
      </List>

      <Modal
        open={isModal}
        onClose={() => {
          setIsModal(false);
          setImage([]);
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <ModalBox>
          <ImageUpload
            images={image}
            setImages={setImage}
            style={{ width: 'fit-content', margin: 0 }}
          />

          <Button
            variant='contained'
            onClick={() =>
              handleCreateBanner(setIsModal as (state: boolean) => void)
            }
            style={{ marginTop: '50px' }}
          >
            Create
          </Button>
        </ModalBox>
      </Modal>
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  min-height: 100%;

  .title_wrap {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 50px;

    &-span {
      font-size: 28px;
      font-weight: 600;
      user-select: none;
    }
  }

  .notice_wrap {
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-bottom: 30px;
  }
`;

const ModalBox = styled.div`
  width: 600px;
  height: 300px;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
