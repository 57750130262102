import axiosAPI from '.';

const signin = async (data: any) => {
  return await axiosAPI.post('/a/signin', data);
};

const userList = async () => {
  return await axiosAPI.get('/a/user');
};

const userUpdate = async (data: any) => {
  return await axiosAPI.put('/a/user', data);
};

const userDelete = async (data: any) => {
  return await axiosAPI.delete('/a/user', { data });
};

const categoryList = async () => {
  return await axiosAPI.get('/a/category');
};

const categoryUpdate = async (data: any) => {
  return await axiosAPI.put('/a/category', data);
};

const categoryCreate = async (data: any) => {
  return await axiosAPI.post('/a/category', data);
};

const notice = async () => {
  return await axiosAPI.get('/a/notice');
};

const noticeUpdate = async (data: any) => {
  return await axiosAPI.put('/a/notice', data);
};

const bannerList = async () => {
  return await axiosAPI.get('/a/banner');
};

const bannerDelete = async (data: any) => {
  return await axiosAPI.delete('/a/banner', { data });
};

const bannerCreate = async (data: any) => {
  return await axiosAPI.post('/a/banner', data);
};

const productCreate = async (data: any) => {
  return await axiosAPI.post('/a/product', data);
};

const productUpdate = async (data: any) => {
  return await axiosAPI.put('/a/product', data);
};

const productDelete = async (data: any) => {
  return await axiosAPI.delete('/a/product', { data });
};

const productList = async () => {
  return await axiosAPI.get('/a/product');
};

const orderList = async () => {
  return await axiosAPI.get('/a/order');
};

const inquiryList = async () => {
  return await axiosAPI.get('/a/inquiry');
};

const orderUpdate = async (data: any) => {
  return await axiosAPI.put('/a/order', data);
};

const root = {
  signin,
  userList,
  userUpdate,
  categoryList,
  categoryUpdate,
  notice,
  noticeUpdate,
  bannerList,
  bannerCreate,
  bannerDelete,
  productList,
  categoryCreate,
  orderList,
  orderUpdate,
  productCreate,
  productUpdate,
  productDelete,
  inquiryList,
  userDelete,
};

export default root;
