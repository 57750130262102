import { combineReducers } from 'redux';

import admin from './admin.reducer';

const rootReducer = combineReducers({
  admin,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
