import { useEffect, useState } from 'react';
import Component from '../../components/product';
import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/reducer';
import {
  handleCategoryListRequest,
  handleProductCreateRequest,
  handleProductDeleteRequest,
  handleProductListRequest,
  handleProductUpdateRequest,
} from '../../redux/action/admin.action';
import useInputs from '../../hooks/useInputs';
import upload, { uploadColorImage } from '../../library/upload.lib';

const initialForm = {
  name: '',
  brand: '',
  details: '',
  guam_original_price: '',
  guam_discount_price: '',
  guam_box_price: '0',
  saipan_original_price: '',
  saipan_discount_price: '',
  saipan_box_price: '0',
  red: '',
  orange: '',
};

export type ProductFormType = typeof initialForm;

const initialSizeForm = {
  size: '',
  guam_quantity_price: '',
  guam_discount_percent: '',
  saipan_quantity_price: '',
  saipan_discount_percent: '',
  guam_box_price: '0',
  saipan_box_price: '0',
};

export type SizeFormType = typeof initialSizeForm;

const initialColorForm = {
  name: '',
  code: '',
};

export type ColorFormType = typeof initialColorForm;

export default function Product() {
  const { addToast } = useToasts();

  const dispatch = useDispatch();
  const { product_list, category_list } = useSelector(
    ({ admin }: RootState) => admin
  );

  const [id, setId] = useState('');

  const [form, onChange, reset] = useInputs(initialForm);

  const [sizeForm, onChangeSize, resetSize] = useInputs(initialSizeForm);
  const [colorForm, onChangeColor, resetColor] = useInputs(initialColorForm);
  const [selectMeasurement, setSelectMeasurement] = useState('ml');

  const [image, setImage] = useState<File[]>([]);
  const [colorImage, setColorImage] = useState<File[]>([]);
  const [prevImage, setPrevImage] = useState<string[]>([]);
  const [sizeList, setSizeList] = useState<any[]>([]);
  const [colorList, setColorList] = useState<any[]>([]);
  const [categoryList, setCategoryList] = useState<any[]>([]);

  const handleReset = () => {
    setSizeList([]);
    setColorList([]);
    setCategoryList([]);
    setImage([]);
    setColorImage([]);
    setSelectMeasurement('ml');
    resetSize(initialSizeForm);
    reset(initialForm);
    setPrevImage([]);
  };

  const handleDetail = (product: any) => {
    setSizeList(product.size_list);
    setColorList(product.color_list);
    setCategoryList(product.category_list);
    setPrevImage(product.image_list);
    reset({
      name: product.name,
      brand: product.brand.name,
      details: product.details,
      guam_original_price: product.guam_original_price,
      guam_discount_price: product.guam_discount_price,
      guam_box_price: product.guam_box_price,
      saipan_original_price: product.saipan_original_price,
      saipan_discount_price: product.saipan_discount_price,
      saipan_box_price: product.saipan_box_price,
      red: product.red,
      orange: product.orange,
    });
    setId(product.id);
  };

  const handleDeleteSize = (id: number) => {
    const size = sizeList.find((s) => s.id === id);
    const list = sizeList.filter((s) => s.id !== id);

    list.push({ ...size, delete: true });

    setSizeList(list);
  };

  const handleDeleteColor = (id: number) => {
    const color = colorList.find((s) => s.id === id);
    const list = colorList.filter((s) => s.id !== id);

    list.push({ ...color, delete: true });

    setColorList(list);
  };

  const handleSize = () => {
    setSizeList([
      ...sizeList,
      {
        size: sizeForm.size,
        measurement: selectMeasurement,
        guam_quantity_price: sizeForm.guam_quantity_price,
        guam_discount_percent: sizeForm.guam_discount_percent,
        guam_box_price: sizeForm.guam_box_price,
        saipan_quantity_price: sizeForm.saipan_quantity_price,
        saipan_discount_percent: sizeForm.saipan_discount_percent,
        saipan_box_price: sizeForm.saipan_box_price,
      },
    ]);
    resetSize(initialSizeForm);
    setSelectMeasurement('ml');
  };

  const handleColor = () => {
    setColorList([
      ...colorList,
      {
        name: colorForm.name,
        code: colorForm.code,
      },
    ]);
    resetColor(initialColorForm);
  };

  const handleCategory = (category: any, isCategory: boolean) => {
    if (isCategory) {
      setCategoryList([...categoryList, category]);
    } else {
      setCategoryList(categoryList.filter((c) => c.id !== category.id));
    }
  };

  const handleDeleteProduct = (setIsModal: (state: boolean) => void) => {
    if (!window.confirm('Are you sure you want to delete this product?')) {
      setIsModal(false);

      return;
    }

    function success() {
      addToast('success delete product', { appearance: 'success' });

      setIsModal(false);
      setSizeList([]);
      setCategoryList([]);
      setImage([]);
      setSelectMeasurement('ml');
      resetSize(initialSizeForm);
      reset(initialForm);

      function success() {
        function success() {}
        function failure() {}

        dispatch(handleCategoryListRequest(success, failure));
      }
      function failure() {}

      dispatch(handleProductListRequest(success, failure));
    }

    function failure() {}

    dispatch(handleProductDeleteRequest({ id }, success, failure));
  };

  const handleUpdateProduct = async (
    setIsModal: (state: boolean) => void,
    product: any
  ) => {
    async function success() {
      addToast('success update product', { appearance: 'success' });

      setIsModal(false);
      setSizeList([]);
      setCategoryList([]);
      setImage([]);
      setSelectMeasurement('ml');
      resetSize(initialSizeForm);
      reset(initialForm);

      function success() {
        function success() {}
        function failure() {}

        dispatch(handleCategoryListRequest(success, failure));
      }
      function failure() {}

      dispatch(handleProductListRequest(success, failure));

      function successDye() {}
      function failureDye() {}

      if (product.product_type === 'dye') {
        let colorList: any[] = [];

        for (let i = 0; i < colorImage.length; i++) {
          await uploadColorImage(
            colorImage[i],
            `${process.env.REACT_APP_ENVIRONMENT}/product/${product.id}/color`
          );

          if (i === colorImage.length - 1) {
            dispatch(
              handleProductUpdateRequest(
                {
                  id: product.id,
                  color_images: [
                    ...colorList,
                    `https://system4u.s3.ap-northeast-2.amazonaws.com/${
                      process.env.REACT_APP_ENVIRONMENT
                    }/product/${product.id}/color/${
                      colorImage[i].name.split('.')[0]
                    }.jpeg`,
                  ],
                },
                successDye,
                failureDye
              )
            );
          } else {
            colorList = [
              ...colorList,
              `https://system4u.s3.ap-northeast-2.amazonaws.com/${
                process.env.REACT_APP_ENVIRONMENT
              }/product/${product.id}/color/${
                colorImage[i].name.split('.')[0]
              }.jpeg`,
            ];
          }
        }
      }
    }

    function failure() {}

    if (!image.length) {
      dispatch(
        handleProductUpdateRequest(
          {
            id,
            ...form,
            size: sizeList,
            color: colorList,
            category: categoryList,
          },
          success,
          failure
        )
      );

      return;
    }

    let list: any[] = [];

    for (let i = 0; i < image.length; i++) {
      const res = await upload(
        image[i],
        `${process.env.REACT_APP_ENVIRONMENT}/product/${id}`
      );

      if (i === image.length - 1) {
        dispatch(
          handleProductUpdateRequest(
            {
              id,
              ...form,
              size: sizeList,
              category: categoryList,
              images: [
                ...list,
                `https://system4u.s3.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}/product/${id}/${res.timestamp}.jpeg`,
              ],
            },
            success,
            failure
          )
        );
      } else {
        list = [
          ...list,
          `https://system4u.s3.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}/product/${id}/${res.timestamp}.jpeg`,
        ];
      }
    }
  };

  const handleUpdateStatus = (id: string, status: string) => {
    function success() {
      addToast('success update product type', { appearance: 'success' });

      function success() {
        function success() {}
        function failure() {}

        dispatch(handleCategoryListRequest(success, failure));
      }
      function failure() {}

      dispatch(handleProductListRequest(success, failure));
    }

    function failure() {}

    dispatch(
      handleProductUpdateRequest(
        {
          id,
          type: status,
        },
        success,
        failure
      )
    );
  };

  const handleCreateProduct = (
    setIsModal: (state: boolean) => void,
    isDye: boolean
  ) => {
    if (
      form.name === '' ||
      form.brand === '' ||
      form.details === '' ||
      form.details === '' ||
      form.guam_original_price === '' ||
      form.saipan_original_price === '' ||
      sizeList.length === 0 ||
      image.length === 0
    ) {
      addToast('Please check the required values!', { appearance: 'warning' });

      return;
    }

    if (isDye && colorList.length === 0) {
      addToast('Please check the color list.', { appearance: 'warning' });

      return;
    } else if (isDye && colorImage.length === 0) {
      addToast('Please check the color images.', { appearance: 'warning' });

      return;
    }

    async function success(response: any) {
      async function success() {
        addToast('success create product', { appearance: 'success' });

        setIsModal(false);
        setSizeList([]);
        setCategoryList([]);
        setImage([]);
        setSelectMeasurement('ml');
        resetSize(initialSizeForm);
        reset(initialForm);

        function success() {
          function success() {}
          function failure() {}

          dispatch(handleCategoryListRequest(success, failure));
        }
        function failure() {}

        dispatch(handleProductListRequest(success, failure));

        function successDye() {}
        function failureDye() {}

        if (response.product_type === 'dye') {
          let colorList: any[] = [];

          for (let i = 0; i < colorImage.length; i++) {
            await uploadColorImage(
              colorImage[i],
              `${process.env.REACT_APP_ENVIRONMENT}/product/${response.id}/color`
            );

            if (i === colorImage.length - 1) {
              dispatch(
                handleProductUpdateRequest(
                  {
                    id: response.id,
                    color_images: [
                      ...colorList,
                      `https://system4u.s3.ap-northeast-2.amazonaws.com/${
                        process.env.REACT_APP_ENVIRONMENT
                      }/product/${response.id}/color/${
                        colorImage[i].name.split('.')[0]
                      }.jpeg`,
                    ],
                  },
                  successDye,
                  failureDye
                )
              );
            } else {
              colorList = [
                ...colorList,
                `https://system4u.s3.ap-northeast-2.amazonaws.com/${
                  process.env.REACT_APP_ENVIRONMENT
                }/product/${response.id}/color/${
                  colorImage[i].name.split('.')[0]
                }.jpeg`,
              ];
            }
          }
        }
      }

      function failure() {}

      let list: any[] = [];

      for (let i = 0; i < image.length; i++) {
        const res = await upload(
          image[i],
          `${process.env.REACT_APP_ENVIRONMENT}/product/${response.id}`
        );

        if (i === image.length - 1) {
          dispatch(
            handleProductUpdateRequest(
              {
                id: response.id,
                images: [
                  ...list,
                  `https://system4u.s3.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}/product/${response.id}/${res.timestamp}.jpeg`,
                ],
              },
              success,
              failure
            )
          );
        } else {
          list = [
            ...list,
            `https://system4u.s3.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}/product/${response.id}/${res.timestamp}.jpeg`,
          ];
        }
      }
    }

    function failure() {}

    dispatch(
      handleProductCreateRequest(
        {
          ...form,
          size: sizeList,
          color: colorList,
          category: categoryList,
        },
        success,
        failure
      )
    );
  };

  useEffect(() => {
    function success() {
      function success() {}
      function failure() {}

      dispatch(handleCategoryListRequest(success, failure));
    }
    function failure() {}

    dispatch(handleProductListRequest(success, failure));
  }, []);

  return (
    <Component
      product_list={product_list}
      category_list={category_list}
      prevImage={prevImage}
      image={image}
      setImage={setImage}
      colorImage={colorImage}
      setColorImage={setColorImage}
      sizeList={sizeList}
      colorList={colorList}
      categoryList={categoryList}
      form={form}
      onChange={onChange}
      sizeForm={sizeForm}
      onChangeSize={onChangeSize}
      colorForm={colorForm}
      onChangeColor={onChangeColor}
      selectMeasurement={selectMeasurement}
      setSelectMeasurement={setSelectMeasurement}
      handleSize={handleSize}
      handleColor={handleColor}
      handleCategory={handleCategory}
      handleCreateProduct={handleCreateProduct}
      handleUpdateStatus={handleUpdateStatus}
      handleDetail={handleDetail}
      handleReset={handleReset}
      handleDeleteSize={handleDeleteSize}
      handleUpdateProduct={handleUpdateProduct}
      handleDeleteProduct={handleDeleteProduct}
      handleDeleteColor={handleDeleteColor}
    />
  );
}
