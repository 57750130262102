import { useEffect, useState } from 'react';
import Component from '../../components/category';
import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/reducer';
import {
  handleCategoryCreateRequest,
  handleCategoryListRequest,
  handleCategoryUpdateRequest,
} from '../../redux/action/admin.action';
import useInputs from '../../hooks/useInputs';

const initialForm = {
  title: '',
  name: '',
  sub_menu: '',
};

export type CategoryType = typeof initialForm;

export default function Category() {
  const { addToast } = useToasts();

  const dispatch = useDispatch();
  const { category_list } = useSelector(({ admin }: RootState) => admin);

  const [form, onChange, reset] = useInputs(initialForm);
  const [isMenu, setIsMenu] = useState(false);

  const handleCreate = (setIsModal: (state: boolean) => void) => {
    function success() {
      addToast('success create category', { appearance: 'success' });

      function success() {
        setIsModal(false);
        reset(initialForm);
        setIsMenu(false);
      }
      function failure() {}

      dispatch(handleCategoryListRequest(success, failure));
    }
    function failure() {}

    dispatch(
      handleCategoryCreateRequest(
        {
          title: form.title,
          name: form.name,
          menu: isMenu,
          sub_menu: form.sub_menu,
        },
        success,
        failure
      )
    );
  };

  const handleUpdate = (id: number, isMenu: boolean, text: string) => {
    function success() {
      addToast('success update category', { appearance: 'success' });

      function success() {}
      function failure() {}

      dispatch(handleCategoryListRequest(success, failure));
    }
    function failure() {}

    dispatch(
      handleCategoryUpdateRequest(
        { id, menu: isMenu, sub_menu: text },
        success,
        failure
      )
    );
  };

  useEffect(() => {
    function success() {}
    function failure() {}

    dispatch(handleCategoryListRequest(success, failure));
  }, []);

  return (
    <Component
      category_list={category_list}
      form={form}
      onChange={onChange}
      isMenu={isMenu}
      setIsMenu={setIsMenu}
      handleUpdate={handleUpdate}
      handleCreate={handleCreate}
    />
  );
}
