import { useEffect, useState } from 'react';
import Component from '../../components/bn';
import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/reducer';
import {
  handleBannerCreateRequest,
  handleBannerDeleteRequest,
  handleBannerListRequest,
  handleNoticeRequest,
  handleNoticeUpdateRequest,
} from '../../redux/action/admin.action';
import upload, { deleteS3 } from '../../library/upload.lib';

export default function BN() {
  const { addToast } = useToasts();

  const dispatch = useDispatch();
  const { notice, banner_list } = useSelector(({ admin }: RootState) => admin);

  const [image, setImage] = useState<File[]>([]);

  const handleCreateBanner = (setIsModal: (state: boolean) => void) => {
    function success() {
      addToast('success create banner', { appearance: 'success' });

      setIsModal(false);
      setImage([]);

      function success() {}
      function failure() {}

      dispatch(handleBannerListRequest(success, failure));
    }
    function failure() {}

    const timestamp = new Date().getTime();

    upload(
      image[0],
      `${process.env.REACT_APP_ENVIRONMENT}/banner/${timestamp}`
    ).then(() => {
      dispatch(
        handleBannerCreateRequest(
          {
            image: `https://system4u.s3.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}/banner/${timestamp}/${timestamp}.jpeg`,
          },
          success,
          failure
        )
      );
    });
  };

  const handleDeleteBanner = (id: number, image: string) => {
    function success() {
      addToast('success delete banner', { appearance: 'success' });

      function success() {}
      function failure() {}

      dispatch(handleBannerListRequest(success, failure));
    }
    function failure() {}

    deleteS3(
      String(image).replace(
        'https://system4u.s3.ap-northeast-2.amazonaws.com/',
        ''
      )
    ).then(() => {
      dispatch(handleBannerDeleteRequest({ id }, success, failure));
    });
  };

  const handleUpdateNotice = (id: number, isNotice: boolean, text: string) => {
    function success() {
      addToast('success update notice', { appearance: 'success' });

      function success() {}
      function failure() {}

      dispatch(handleNoticeRequest(success, failure));
    }
    function failure() {}

    dispatch(
      handleNoticeUpdateRequest(
        { id, status: isNotice, text },
        success,
        failure
      )
    );
  };

  useEffect(() => {
    function success() {}
    function failure() {}

    dispatch(handleNoticeRequest(success, failure));

    function successB() {}
    function failureB() {}

    dispatch(handleBannerListRequest(successB, failureB));
  }, []);

  return (
    <Component
      notice={notice}
      handleUpdateNotice={handleUpdateNotice}
      banner_list={banner_list}
      handleDeleteBanner={handleDeleteBanner}
      image={image}
      setImage={setImage}
      handleCreateBanner={handleCreateBanner}
    />
  );
}
