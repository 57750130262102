import PageTemplate from '../containers/common/PageTemplate';

import Container from '../containers/category';

export default function Category() {
  return (
    <>
      <PageTemplate>
        <Container />
      </PageTemplate>
    </>
  );
}
