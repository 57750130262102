import { handleActions } from 'redux-actions';

import * as Action from '../action/admin.action';

export type State = {
  user_list: any[];
  product_list: any[];
  category_list: any[];
  order_list: any[];
  inquiry_list: any[];
  banner_list: any[];
  notice: any;
};

export type Payload = {
  data: any;
  success: (response: any) => void;
  failure: (error: any) => void;
};

const initialState: State = {
  user_list: [],
  product_list: [],
  category_list: [],
  order_list: [],
  inquiry_list: [],
  banner_list: [],
  notice: null,
};

const product = handleActions<State, Payload>(
  {
    [Action.SIGN_IN_SUCCESS]: (state, _action) => ({
      ...state,
    }),
    [Action.SIGN_IN_FAILURE]: (state, _action) => ({
      ...state,
    }),

    [Action.USER_LIST_SUCCESS]: (state, action) => ({
      ...state,
      user_list: action.payload.data,
    }),
    [Action.USER_LIST_FAILURE]: (state, _action) => ({
      ...state,
    }),
    [Action.USER_UPDATE_SUCCESS]: (state, _action) => ({
      ...state,
    }),
    [Action.USER_UPDATE_FAILURE]: (state, _action) => ({
      ...state,
    }),
    [Action.USER_DELETE_SUCCESS]: (state, action) => ({
      ...state,
    }),
    [Action.USER_DELETE_FAILURE]: (state, _action) => ({
      ...state,
    }),

    [Action.CATEGORY_LIST_SUCCESS]: (state, action) => ({
      ...state,
      category_list: action.payload.data,
    }),
    [Action.CATEGORY_LIST_FAILURE]: (state, _action) => ({
      ...state,
    }),
    [Action.CATEGORY_CREATE_SUCCESS]: (state, _action) => ({
      ...state,
    }),
    [Action.CATEGORY_CREATE_FAILURE]: (state, _action) => ({
      ...state,
    }),
    [Action.CATEGORY_UPDATE_SUCCESS]: (state, _action) => ({
      ...state,
    }),
    [Action.CATEGORY_UPDATE_FAILURE]: (state, _action) => ({
      ...state,
    }),

    [Action.NOTICE_SUCCESS]: (state, action) => ({
      ...state,
      notice: action.payload.data,
    }),
    [Action.NOTICE_FAILURE]: (state, _action) => ({
      ...state,
    }),
    [Action.NOTICE_UPDATE_SUCCESS]: (state, _action) => ({
      ...state,
    }),
    [Action.NOTICE_UPDATE_FAILURE]: (state, _action) => ({
      ...state,
    }),

    [Action.BANNER_LIST_SUCCESS]: (state, action) => ({
      ...state,
      banner_list: action.payload.data,
    }),
    [Action.BANNER_LIST_FAILURE]: (state, _action) => ({
      ...state,
    }),
    [Action.BANNER_DELETE_SUCCESS]: (state, _action) => ({
      ...state,
    }),
    [Action.BANNER_DELETE_FAILURE]: (state, _action) => ({
      ...state,
    }),
    [Action.BANNER_CREATE_SUCCESS]: (state, _action) => ({
      ...state,
    }),
    [Action.BANNER_CREATE_FAILURE]: (state, _action) => ({
      ...state,
    }),

    [Action.PRODUCT_CREATE_SUCCESS]: (state, action) => ({
      ...state,
    }),
    [Action.PRODUCT_CREATE_FAILURE]: (state, _action) => ({
      ...state,
    }),
    [Action.PRODUCT_UPDATE_SUCCESS]: (state, action) => ({
      ...state,
    }),
    [Action.PRODUCT_UPDATE_FAILURE]: (state, _action) => ({
      ...state,
    }),
    [Action.PRODUCT_DELETE_SUCCESS]: (state, action) => ({
      ...state,
    }),
    [Action.PRODUCT_DELETE_FAILURE]: (state, _action) => ({
      ...state,
    }),
    [Action.PRODUCT_LIST_SUCCESS]: (state, action) => ({
      ...state,
      product_list: action.payload.data,
    }),
    [Action.PRODUCT_LIST_FAILURE]: (state, _action) => ({
      ...state,
    }),

    [Action.ORDER_LIST_SUCCESS]: (state, action) => ({
      ...state,
      order_list: action.payload.data,
    }),
    [Action.ORDER_LIST_FAILURE]: (state, _action) => ({
      ...state,
    }),
    [Action.ORDER_UPDATE_SUCCESS]: (state, _action) => ({
      ...state,
    }),
    [Action.ORDER_UPDATE_FAILURE]: (state, _action) => ({
      ...state,
    }),

    [Action.INQUIRY_SUCCESS]: (state, action) => ({
      ...state,
      inquiry_list: action.payload.data,
    }),
    [Action.INQUIRY_FAILURE]: (state, _action) => ({
      ...state,
    }),
  },
  initialState
);

export default product;
