import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'redux-actions';

import { Payload } from '../reducer/admin.reducer';

import * as ACTION from '../action/admin.action';

import API from '../api/admin.api';

function* signin(action: Action<Payload>): Generator<any, void, any> {
  try {
    const {
      data: { data },
    } = yield call(API.signin, action.payload.data);

    action.payload.success(data);

    yield put({
      type: ACTION.SIGN_IN_SUCCESS,
      payload: { data },
    });
  } catch (error: any) {
    if (error.response) {
      action.payload.failure(error.response.data);

      yield put({
        type: ACTION.SIGN_IN_FAILURE,
        payload: { data: error.response.data },
      });
      return;
    }

    yield put({
      type: ACTION.SIGN_IN_FAILURE,
      payload: { data: error },
    });
  }
}

function* userList(action: Action<Payload>): Generator<any, void, any> {
  try {
    const {
      data: { data },
    } = yield call(API.userList);

    action.payload.success(data);

    yield put({
      type: ACTION.USER_LIST_SUCCESS,
      payload: { data },
    });
  } catch (error: any) {
    if (error.response) {
      action.payload.failure(error.response.data);

      yield put({
        type: ACTION.USER_LIST_FAILURE,
        payload: { data: error.response.data },
      });
      return;
    }

    yield put({
      type: ACTION.USER_LIST_FAILURE,
      payload: { data: error },
    });
  }
}

function* userUpdate(action: Action<Payload>): Generator<any, void, any> {
  try {
    const {
      data: { data },
    } = yield call(API.userUpdate, action.payload.data);

    action.payload.success(data);

    yield put({
      type: ACTION.USER_UPDATE_SUCCESS,
      payload: { data },
    });
  } catch (error: any) {
    if (error.response) {
      action.payload.failure(error.response.data);

      yield put({
        type: ACTION.USER_UPDATE_FAILURE,
        payload: { data: error.response.data },
      });
      return;
    }

    yield put({
      type: ACTION.USER_UPDATE_FAILURE,
      payload: { data: error },
    });
  }
}

function* userDelete(action: Action<Payload>): Generator<any, void, any> {
  try {
    const {
      data: { data },
    } = yield call(API.userDelete, action.payload.data);

    action.payload.success(data);

    yield put({
      type: ACTION.USER_DELETE_SUCCESS,
      payload: { data },
    });
  } catch (error: any) {
    if (error.response) {
      action.payload.failure(error.response.data);

      yield put({
        type: ACTION.USER_DELETE_FAILURE,
        payload: { data: error.response.data },
      });
      return;
    }

    yield put({
      type: ACTION.USER_DELETE_FAILURE,
      payload: { data: error },
    });
  }
}

function* categoryList(action: Action<Payload>): Generator<any, void, any> {
  try {
    const {
      data: { data },
    } = yield call(API.categoryList);

    action.payload.success(data);

    yield put({
      type: ACTION.CATEGORY_LIST_SUCCESS,
      payload: { data },
    });
  } catch (error: any) {
    if (error.response) {
      action.payload.failure(error.response.data);

      yield put({
        type: ACTION.CATEGORY_LIST_FAILURE,
        payload: { data: error.response.data },
      });
      return;
    }

    yield put({
      type: ACTION.CATEGORY_LIST_FAILURE,
      payload: { data: error },
    });
  }
}

function* categoryUpdate(action: Action<Payload>): Generator<any, void, any> {
  try {
    const {
      data: { data },
    } = yield call(API.categoryUpdate, action.payload.data);

    action.payload.success(data);

    yield put({
      type: ACTION.CATEGORY_UPDATE_SUCCESS,
      payload: { data },
    });
  } catch (error: any) {
    if (error.response) {
      action.payload.failure(error.response.data);

      yield put({
        type: ACTION.CATEGORY_UPDATE_FAILURE,
        payload: { data: error.response.data },
      });
      return;
    }

    yield put({
      type: ACTION.CATEGORY_UPDATE_FAILURE,
      payload: { data: error },
    });
  }
}

function* categoryCreate(action: Action<Payload>): Generator<any, void, any> {
  try {
    const {
      data: { data },
    } = yield call(API.categoryCreate, action.payload.data);

    action.payload.success(data);

    yield put({
      type: ACTION.CATEGORY_CREATE_SUCCESS,
      payload: { data },
    });
  } catch (error: any) {
    if (error.response) {
      action.payload.failure(error.response.data);

      yield put({
        type: ACTION.CATEGORY_CREATE_FAILURE,
        payload: { data: error.response.data },
      });
      return;
    }

    yield put({
      type: ACTION.CATEGORY_CREATE_FAILURE,
      payload: { data: error },
    });
  }
}

function* notice(action: Action<Payload>): Generator<any, void, any> {
  try {
    const {
      data: { data },
    } = yield call(API.notice);

    action.payload.success(data);

    yield put({
      type: ACTION.NOTICE_SUCCESS,
      payload: { data },
    });
  } catch (error: any) {
    if (error.response) {
      action.payload.failure(error.response.data);

      yield put({
        type: ACTION.NOTICE_FAILURE,
        payload: { data: error.response.data },
      });
      return;
    }

    yield put({
      type: ACTION.NOTICE_FAILURE,
      payload: { data: error },
    });
  }
}

function* noticeUpdate(action: Action<Payload>): Generator<any, void, any> {
  try {
    const {
      data: { data },
    } = yield call(API.noticeUpdate, action.payload.data);

    action.payload.success(data);

    yield put({
      type: ACTION.NOTICE_UPDATE_SUCCESS,
      payload: { data },
    });
  } catch (error: any) {
    if (error.response) {
      action.payload.failure(error.response.data);

      yield put({
        type: ACTION.NOTICE_UPDATE_FAILURE,
        payload: { data: error.response.data },
      });
      return;
    }

    yield put({
      type: ACTION.NOTICE_UPDATE_FAILURE,
      payload: { data: error },
    });
  }
}

function* bannerList(action: Action<Payload>): Generator<any, void, any> {
  try {
    const {
      data: { data },
    } = yield call(API.bannerList);

    action.payload.success(data);

    yield put({
      type: ACTION.BANNER_LIST_SUCCESS,
      payload: { data },
    });
  } catch (error: any) {
    if (error.response) {
      action.payload.failure(error.response.data);

      yield put({
        type: ACTION.BANNER_LIST_FAILURE,
        payload: { data: error.response.data },
      });
      return;
    }

    yield put({
      type: ACTION.BANNER_LIST_FAILURE,
      payload: { data: error },
    });
  }
}

function* bannerDelete(action: Action<Payload>): Generator<any, void, any> {
  try {
    const {
      data: { data },
    } = yield call(API.bannerDelete, action.payload.data);

    action.payload.success(data);

    yield put({
      type: ACTION.BANNER_DELETE_SUCCESS,
      payload: { data },
    });
  } catch (error: any) {
    if (error.response) {
      action.payload.failure(error.response.data);

      yield put({
        type: ACTION.BANNER_DELETE_FAILURE,
        payload: { data: error.response.data },
      });
      return;
    }

    yield put({
      type: ACTION.BANNER_DELETE_FAILURE,
      payload: { data: error },
    });
  }
}

function* bannerCreate(action: Action<Payload>): Generator<any, void, any> {
  try {
    const {
      data: { data },
    } = yield call(API.bannerCreate, action.payload.data);

    action.payload.success(data);

    yield put({
      type: ACTION.BANNER_CREATE_SUCCESS,
      payload: { data },
    });
  } catch (error: any) {
    if (error.response) {
      action.payload.failure(error.response.data);

      yield put({
        type: ACTION.BANNER_CREATE_FAILURE,
        payload: { data: error.response.data },
      });
      return;
    }

    yield put({
      type: ACTION.BANNER_CREATE_FAILURE,
      payload: { data: error },
    });
  }
}

function* productCreate(action: Action<Payload>): Generator<any, void, any> {
  try {
    const {
      data: { data },
    } = yield call(API.productCreate, action.payload.data);

    action.payload.success(data);

    yield put({
      type: ACTION.PRODUCT_CREATE_SUCCESS,
      payload: { data },
    });
  } catch (error: any) {
    if (error.response) {
      action.payload.failure(error.response.data);

      yield put({
        type: ACTION.PRODUCT_CREATE_FAILURE,
        payload: { data: error.response.data },
      });
      return;
    }

    yield put({
      type: ACTION.PRODUCT_CREATE_FAILURE,
      payload: { data: error },
    });
  }
}

function* productUpdate(action: Action<Payload>): Generator<any, void, any> {
  try {
    const {
      data: { data },
    } = yield call(API.productUpdate, action.payload.data);

    action.payload.success(data);

    yield put({
      type: ACTION.PRODUCT_UPDATE_SUCCESS,
      payload: { data },
    });
  } catch (error: any) {
    if (error.response) {
      action.payload.failure(error.response.data);

      yield put({
        type: ACTION.PRODUCT_UPDATE_FAILURE,
        payload: { data: error.response.data },
      });
      return;
    }

    yield put({
      type: ACTION.PRODUCT_UPDATE_FAILURE,
      payload: { data: error },
    });
  }
}

function* productDelete(action: Action<Payload>): Generator<any, void, any> {
  try {
    const {
      data: { data },
    } = yield call(API.productDelete, action.payload.data);

    action.payload.success(data);

    yield put({
      type: ACTION.PRODUCT_DELETE_SUCCESS,
      payload: { data },
    });
  } catch (error: any) {
    if (error.response) {
      action.payload.failure(error.response.data);

      yield put({
        type: ACTION.PRODUCT_DELETE_FAILURE,
        payload: { data: error.response.data },
      });
      return;
    }

    yield put({
      type: ACTION.PRODUCT_DELETE_FAILURE,
      payload: { data: error },
    });
  }
}

function* productList(action: Action<Payload>): Generator<any, void, any> {
  try {
    const {
      data: { data },
    } = yield call(API.productList);

    action.payload.success(data);

    yield put({
      type: ACTION.PRODUCT_LIST_SUCCESS,
      payload: { data },
    });
  } catch (error: any) {
    if (error.response) {
      action.payload.failure(error.response.data);

      yield put({
        type: ACTION.PRODUCT_LIST_FAILURE,
        payload: { data: error.response.data },
      });
      return;
    }

    yield put({
      type: ACTION.PRODUCT_LIST_FAILURE,
      payload: { data: error },
    });
  }
}

function* orderList(action: Action<Payload>): Generator<any, void, any> {
  try {
    const {
      data: { data },
    } = yield call(API.orderList);

    action.payload.success(data);

    yield put({
      type: ACTION.ORDER_LIST_SUCCESS,
      payload: { data },
    });
  } catch (error: any) {
    if (error.response) {
      action.payload.failure(error.response.data);

      yield put({
        type: ACTION.ORDER_LIST_FAILURE,
        payload: { data: error.response.data },
      });
      return;
    }

    yield put({
      type: ACTION.ORDER_LIST_FAILURE,
      payload: { data: error },
    });
  }
}

function* orderUpdate(action: Action<Payload>): Generator<any, void, any> {
  try {
    const {
      data: { data },
    } = yield call(API.orderUpdate, action.payload.data);

    action.payload.success(data);

    yield put({
      type: ACTION.ORDER_UPDATE_SUCCESS,
      payload: { data },
    });
  } catch (error: any) {
    if (error.response) {
      action.payload.failure(error.response.data);

      yield put({
        type: ACTION.ORDER_UPDATE_FAILURE,
        payload: { data: error.response.data },
      });
      return;
    }

    yield put({
      type: ACTION.ORDER_UPDATE_FAILURE,
      payload: { data: error },
    });
  }
}

function* inquiryList(action: Action<Payload>): Generator<any, void, any> {
  try {
    const {
      data: { data },
    } = yield call(API.inquiryList);

    action.payload.success(data);

    yield put({
      type: ACTION.INQUIRY_SUCCESS,
      payload: { data },
    });
  } catch (error: any) {
    if (error.response) {
      action.payload.failure(error.response.data);

      yield put({
        type: ACTION.INQUIRY_FAILURE,
        payload: { data: error.response.data },
      });
      return;
    }

    yield put({
      type: ACTION.INQUIRY_FAILURE,
      payload: { data: error },
    });
  }
}

function* watchSignin() {
  yield takeLatest(ACTION.SIGN_IN_REQUEST, signin);
}

function* watchUserList() {
  yield takeLatest(ACTION.USER_LIST_REQUEST, userList);
}
function* watchUserUpdate() {
  yield takeLatest(ACTION.USER_UPDATE_REQUEST, userUpdate);
}
function* watchUserDelete() {
  yield takeLatest(ACTION.USER_DELETE_REQUEST, userDelete);
}

function* watchCategoryList() {
  yield takeLatest(ACTION.CATEGORY_LIST_REQUEST, categoryList);
}
function* watchCategoryCreate() {
  yield takeLatest(ACTION.CATEGORY_CREATE_REQUEST, categoryCreate);
}
function* watchCategoryUpdate() {
  yield takeLatest(ACTION.CATEGORY_UPDATE_REQUEST, categoryUpdate);
}

function* watchNotice() {
  yield takeLatest(ACTION.NOTICE_REQUEST, notice);
}
function* watchNoticeUpdate() {
  yield takeLatest(ACTION.NOTICE_UPDATE_REQUEST, noticeUpdate);
}

function* watchBannerList() {
  yield takeLatest(ACTION.BANNER_LIST_REQUEST, bannerList);
}
function* watchBannerDelete() {
  yield takeLatest(ACTION.BANNER_DELETE_REQUEST, bannerDelete);
}
function* watchBannerCreate() {
  yield takeLatest(ACTION.BANNER_CREATE_REQUEST, bannerCreate);
}

function* watchProductCreate() {
  yield takeLatest(ACTION.PRODUCT_CREATE_REQUEST, productCreate);
}
function* watchProductUpdate() {
  yield takeLatest(ACTION.PRODUCT_UPDATE_REQUEST, productUpdate);
}
function* watchProductDelete() {
  yield takeLatest(ACTION.PRODUCT_DELETE_REQUEST, productDelete);
}
function* watchProductList() {
  yield takeLatest(ACTION.PRODUCT_LIST_REQUEST, productList);
}

function* watchOrderList() {
  yield takeLatest(ACTION.ORDER_LIST_REQUEST, orderList);
}
function* watchOrderUpdate() {
  yield takeLatest(ACTION.ORDER_UPDATE_REQUEST, orderUpdate);
}

function* watchInquiry() {
  yield takeLatest(ACTION.INQUIRY_REQUEST, inquiryList);
}

function* productSaga() {
  yield all([
    fork(watchSignin),
    fork(watchUserList),
    fork(watchUserUpdate),
    fork(watchCategoryList),
    fork(watchCategoryUpdate),
    fork(watchNotice),
    fork(watchNoticeUpdate),
    fork(watchBannerList),
    fork(watchBannerDelete),
    fork(watchBannerCreate),
    fork(watchProductList),
    fork(watchCategoryCreate),
    fork(watchOrderList),
    fork(watchOrderUpdate),
    fork(watchProductCreate),
    fork(watchProductUpdate),
    fork(watchProductDelete),
    fork(watchInquiry),
    fork(watchUserDelete),
  ]);
}

export default productSaga;
