import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from 'react-router-dom';
import { DefaultTheme, ThemeProvider } from 'styled-components';
import {
  applyMiddleware,
  compose,
  legacy_createStore as createStore,
} from 'redux';
import createSagaMiddleware from '@redux-saga/core';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import { ToastProvider } from 'react-toast-notifications';

import * as Page from './pages';

import rootReducer from './redux/reducer';
import rootSaga from './redux/saga';

import GlobalStyle from './style/globalStyle';
import theme from './constants/theme';

const sagaMiddleware = createSagaMiddleware();

const loggerMiddleware: any = createLogger();

const enhancer =
  process.env.REACT_APP_ENVIRONMENT === 'development'
    ? composeWithDevTools(applyMiddleware(loggerMiddleware, sagaMiddleware))
    : compose(applyMiddleware(sagaMiddleware));

const store = createStore(rootReducer, enhancer);

sagaMiddleware.run(rootSaga);

const routes = [
  {
    path: '/',
    exact: true,
    main: () => <Page.Main />,
  },
  {
    path: '/product',
    exact: true,
    main: () => <Page.Product />,
  },
  {
    path: '/category',
    exact: true,
    main: () => <Page.Category />,
  },
  {
    path: '/brand',
    exact: true,
    main: () => <Page.Brand />,
  },
  {
    path: '/order',
    exact: true,
    main: () => <Page.Order />,
  },
  {
    path: '/inquiry',
    exact: true,
    main: () => <Page.Inquiry />,
  },
  {
    path: '/bn',
    exact: true,
    main: () => <Page.BN />,
  },
  {
    path: '/sign',
    exact: true,
    main: () => <Page.Sign />,
  },
];

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <Provider store={store}>
      <ToastProvider autoDismiss={true} autoDismissTimeout={3000}>
        <ThemeProvider theme={theme as DefaultTheme}>
          <GlobalStyle />
          <Router>
            <ScrollToTop />
            <Switch>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  children={<route.main />}
                />
              ))}
            </Switch>
          </Router>
        </ThemeProvider>
      </ToastProvider>
    </Provider>
  );
}

export default App;
