import { ReactChild } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import tokenLib from '../../library/token.lib';

type PageTemplateProps = {
  children: ReactChild | ReactChild[];
};

export default function PageTemplate({ children }: PageTemplateProps) {
  const history = useHistory();

  return (
    <>
      <Box>
        <div className={cn('panel')}>
          <span className={cn('panel-title')}>SYSTEM4U ADMIN</span>

          <span className={cn('panel-span')} onClick={() => history.push('/')}>
            User
          </span>

          <span
            className={cn('panel-span')}
            onClick={() => history.push('/product')}
          >
            Product
          </span>

          <span
            className={cn('panel-span')}
            onClick={() => history.push('/category')}
          >
            Category
          </span>

          <span
            className={cn('panel-span')}
            onClick={() => history.push('/order')}
          >
            Order
          </span>

          <span
            className={cn('panel-span')}
            onClick={() => history.push('/inquiry')}
          >
            Inquiry
          </span>

          <span
            className={cn('panel-span')}
            onClick={() => history.push('/bn')}
          >
            Banner & Notice
          </span>

          <span
            className={cn('panel-signout')}
            onClick={() => {
              tokenLib.removeToken('ALL');

              history.push('/sign');
            }}
          >
            sign out
          </span>
        </div>

        <div className={cn('contents')}>{children}</div>
      </Box>
    </>
  );
}

const Box = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: #eef2f6;

  .panel {
    width: 250px;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 30px 0;

    &-title {
      width: fit-content;
      height: fit-content;
      font-size: 24px;
      font-weight: 500;
      margin: auto;
      margin-top: 0;
      user-select: none;
    }

    &-span {
      width: fit-content;
      height: fit-content;
      font-size: 18px;
      font-weight: 500;
      margin: 40px auto;
      cursor: pointer;
      user-select: none;

      &:hover {
        color: #339af0;
      }
    }

    &-signout {
      width: fit-content;
      height: fit-content;
      font-size: 18px;
      font-weight: 500;
      margin: auto;
      margin-bottom: 0;
      cursor: pointer;
      user-select: none;
      color: #e03131;
    }
  }

  .contents {
    width: calc(100% - 350px);
    height: calc(100% - 100px);
    margin: 50px;
    padding: 50px;
    background-color: #fff;
    border-radius: 5px;
    overflow: auto;
  }
`;
