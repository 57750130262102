import styled from 'styled-components';
import cn from 'classnames';
import { Button, TextField } from '@mui/material';
import { FormType } from '../../containers/sign';
import { ChangeEvent } from 'react';

type SignProps = {
  form: FormType;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSignIn: () => void;
};

export default function Sign({ form, onChange, handleSignIn }: SignProps) {
  return (
    <Box>
      <div className={cn('wrap')}>
        <span className={cn('wrap-title')}>SYSTEM4U ADMIN</span>

        <TextField
          id='id'
          label='Email'
          name='email'
          value={form.email}
          onChange={onChange}
          variant='outlined'
          style={{
            width: '250px',
            height: '50px',
            margin: 'auto',
            marginBottom: '30px',
          }}
        />

        <TextField
          id='password'
          label='Password'
          type='password'
          variant='outlined'
          name='password'
          value={form.password}
          onChange={onChange}
          style={{
            width: '250px',
            height: '50px',
            margin: 'auto',
            marginTop: '0',
          }}
        />

        <Button
          variant='contained'
          size='large'
          style={{
            width: '250px',
            height: '50px',
            margin: 'auto',
            marginBottom: '0',
          }}
          onClick={handleSignIn}
        >
          Sign In
        </Button>
      </div>
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #eef2f6;

  .wrap {
    width: 500px;
    height: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px;
    background-color: #fff;
    border-radius: 5px;

    &-title {
      width: fit-content;
      height: fit-content;
      font-size: 28px;
      font-weight: 500;
      margin: auto;
      margin-top: 0;
    }
  }
`;
