import { useEffect } from 'react';
import Component from '../../components/inquiry';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/reducer';
import { handleInquiryRequest } from '../../redux/action/admin.action';

export default function Inquiry() {
  const dispatch = useDispatch();
  const { inquiry_list } = useSelector(({ admin }: RootState) => admin);

  useEffect(() => {
    function success() {}
    function failure() {}

    dispatch(handleInquiryRequest(success, failure));
  }, []);

  return <Component list={inquiry_list} />;
}
