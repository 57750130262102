import AWS from 'aws-sdk';
import { DeleteObjectOutput, ManagedUpload } from 'aws-sdk/clients/s3';
import Resizer from 'react-image-file-resizer';

type UploadResponse = {
  timestamp: string;
  data: ManagedUpload.SendData;
};

AWS.config.update({
  region: 'ap-northeast-2',
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: String(process.env.REACT_APP_AWS_IDENTITY_POOL_ID),
  }),
});

const s3 = new AWS.S3({
  apiVersion: '2006-03-01',
});

const resizeFile = (file: File): Promise<string> =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1200,
      1200,
      'JPEG',
      100,
      0,
      (uri) => {
        resolve(uri as string);
      },
      'file'
    );
  });

export function deleteS3(path: string): Promise<DeleteObjectOutput> {
  return new Promise((resolve, reject) => {
    s3.deleteObject(
      {
        Bucket: 'system4u',
        Key: `${path}.jpeg`,
      },
      (error, data) => {
        if (error) {
          reject(error);
        }

        resolve(data);
      }
    );
  });
}

export default function upload(
  file: File,
  path: string
): Promise<UploadResponse> {
  return new Promise((resolve, reject) => {
    const timestamp = new Date().getTime();

    resizeFile(file).then((uri) => {
      s3.upload(
        {
          Bucket: 'system4u',
          Key: `${path}/${timestamp}.jpeg`,
          Body: uri,
          ContentType: 'image/jpeg',
        },
        (error, data) => {
          if (error) {
            reject(error);
          }

          resolve({ timestamp: String(timestamp), data });
        }
      );
    });
  });
}

export function uploadColorImage(
  file: File,
  path: string
): Promise<UploadResponse> {
  return new Promise((resolve, reject) => {
    const timestamp = new Date().getTime();

    resizeFile(file).then((uri) => {
      s3.upload(
        {
          Bucket: 'system4u',
          Key: `${path}/${file.name.split('.')[0]}.jpeg`,
          Body: uri,
          ContentType: 'image/jpeg',
        },
        (error, data) => {
          if (error) {
            reject(error);
          }

          resolve({ timestamp: String(timestamp), data });
        }
      );
    });
  });
}
