import { ReactChild, useEffect } from 'react';
import Component from '../../components/common/PageTemplate';
import tokenLib from '../../library/token.lib';
import { useHistory } from 'react-router-dom';

type PageTemplateProps = {
  children: ReactChild | ReactChild[];
};

export default function PageTemplate({ children }: PageTemplateProps) {
  const history = useHistory();

  useEffect(() => {
    const token = tokenLib.getRefreshToken();

    if (!token) {
      history.push('/sign');

      return;
    }
  }, []);

  return (
    <>
      <Component>{children}</Component>
    </>
  );
}
