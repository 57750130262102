import { useState, ChangeEvent } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import {
  Button,
  FormControlLabel,
  Modal,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { CategoryType } from '../../containers/category';

type CategoryProps = {
  category_list: any[];
  form: CategoryType;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isMenu: boolean;
  setIsMenu: (state: boolean) => void;
  handleUpdate: (id: number, isMenu: boolean, text: string) => void;
  handleCreate: (setIsModal: (state: boolean) => void) => void;
};

export default function Category({
  category_list,
  form,
  onChange,
  isMenu,
  setIsMenu,
  handleUpdate,
  handleCreate,
}: CategoryProps) {
  const [isModal, setIsModal] = useState(false);

  return (
    <Box>
      <div className={cn('title_wrap')}>
        <span className={cn('title_wrap-span')}>Category</span>

        <Button variant='contained' onClick={() => setIsModal(true)}>
          Create Category
        </Button>
      </div>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell align='center'>ID</TableCell>
              <TableCell align='center'>Title</TableCell>
              <TableCell align='center'>Name</TableCell>
              <TableCell align='center'>Menu</TableCell>
              <TableCell align='center'>Sub Menu</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {category_list.map((category: any, index: number) => (
              <TableRow
                key={index}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  cursor: 'pointer',
                }}
              >
                <TableCell component='th' scope='row' align='center'>
                  {index + 1}
                </TableCell>
                <TableCell align='center'>{category.title}</TableCell>
                <TableCell align='center'>{category.name}</TableCell>
                <TableCell align='center'>
                  <Switch
                    aria-label='switch'
                    defaultChecked={category.menu}
                    onChange={(e) =>
                      handleUpdate(
                        category.id,
                        e.target.checked,
                        category.sub_menu
                      )
                    }
                  />
                </TableCell>
                <TableCell align='center'>
                  <TextField
                    id='standard-basic'
                    label='Sub Menu'
                    variant='standard'
                    defaultValue={category.sub_menu}
                    onBlur={(e) =>
                      handleUpdate(category.id, category.menu, e.target.value)
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={isModal}
        onClose={() => {
          setIsModal(false);
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <ModalBox>
          <TextField
            id='standard-basic'
            label='Title'
            variant='outlined'
            name='title'
            value={form.title}
            onChange={onChange}
            style={{ marginBottom: '30px' }}
          />

          <TextField
            id='standard-basic'
            label='Name'
            variant='outlined'
            name='name'
            value={form.name}
            onChange={onChange}
            style={{ marginBottom: '30px' }}
          />

          <FormControlLabel
            control={
              <Switch value={isMenu} onClick={() => setIsMenu(!isMenu)} />
            }
            label='Menu'
            style={{ marginBottom: '30px', userSelect: 'none' }}
          />

          <TextField
            id='standard-basic'
            label='Sub Menu'
            variant='outlined'
            name='sub_menu'
            value={form.sub_menu}
            onChange={onChange}
          />

          <Button
            variant='contained'
            onClick={() => handleCreate(setIsModal as (state: boolean) => void)}
            style={{ marginTop: '50px' }}
          >
            Create
          </Button>
        </ModalBox>
      </Modal>
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  min-height: 100%;

  .title_wrap {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-bottom: 50px;

    &-span {
      font-size: 28px;
      font-weight: 600;
      user-select: none;
    }
  }
`;

const ModalBox = styled.div`
  width: 600px;
  height: 500px;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
