import { useState, Fragment } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';

type OrderProps = {
  order_list: any[];
  handleUpdateStatus: (id: string, value: string) => void;
};

export default function Order({ order_list, handleUpdateStatus }: OrderProps) {
  const [isModal, setIsModal] = useState(false);
  const [detail, setDetail] = useState<any>(null);

  const handleModal = (data: any) => {
    setIsModal(true);
    setDetail(data);
  };

  return (
    <Box>
      <div className={cn('title_wrap')}>
        <span className={cn('title_wrap-span')}>Order</span>
      </div>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell align='center'>ID</TableCell>
              <TableCell align='center'>Orderer</TableCell>
              <TableCell align='center'>Delivery</TableCell>
              <TableCell align='center'>Status</TableCell>
              <TableCell align='center'>Created At</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {order_list.map((order: any, index: number) => {
              return (
                <TableRow
                  key={index}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    cursor: 'pointer',
                  }}
                >
                  <TableCell
                    component='th'
                    scope='row'
                    align='center'
                    onClick={() => handleModal(order)}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell align='center' onClick={() => handleModal(order)}>
                    {order.user.name}
                  </TableCell>
                  <TableCell align='center' onClick={() => handleModal(order)}>
                    {order.delivery}
                  </TableCell>
                  <TableCell align='center'>
                    <FormControl style={{ width: '230px' }}>
                      <InputLabel id='demo-simple-select-label'>
                        Status
                      </InputLabel>
                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        defaultValue={order.status}
                        label='Status'
                        onChange={(e) =>
                          handleUpdateStatus(order.id, e.target.value)
                        }
                      >
                        <MenuItem value={'init'}>Init</MenuItem>
                        <MenuItem value={'pending'}>Pending</MenuItem>
                        <MenuItem value={'cancel'}>Cancel</MenuItem>
                        <MenuItem value={'confirm'}>Confirm</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell align='center'>
                    {dayjs(order.created_at).format('YYYY-MM-DD')}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={isModal}
        onClose={() => {
          setIsModal(false);
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <ModalBox>
          {detail ? (
            <>
              <span className={cn('title')}>User Info</span>
              <span className={cn('content')}>name: {detail.user.name}</span>
              <span className={cn('content')}>
                address:{' '}
                {`${detail.user.address_list[0].address} ${detail.user.address_list[0].detail_address}`}
              </span>
              <span className={cn('content')}>email: {detail.user.email}</span>
              <span className={cn('content')} style={{ marginBottom: '20px' }}>
                mobile: {detail.user.mobile}
              </span>

              <span className={cn('title')}>Order Detail</span>
              <span className={cn('content')}>delivery: {detail.delivery}</span>
              <span className={cn('content')}>status: {detail.status}</span>
              <span className={cn('content')} style={{ marginBottom: '20px' }}>
                created at: {dayjs(detail.created_at).format('YYYY-MM-DD')}
              </span>

              {detail.detail_list.map((data: any) => {
                const product = detail.product_list.find(
                  (p: any) => p.id === data.product_id
                );

                return (
                  <>
                    <span className={cn('subtitle')}>
                      Product: {product.name}
                    </span>
                    <span className={cn('content')}>
                      brand: {product.brand.name}
                    </span>
                    <span className={cn('content')}>
                      [Count/Size]{' '}
                      {`${
                        data.count_type === 'box'
                          ? `${data.count} Box`
                          : data.count
                      }/${
                        data.measurement === 'ml'
                          ? `${data.size / 1000} Liter`
                          : `${data.size} fl oz`
                      }`}
                    </span>
                    <span
                      className={cn('content')}
                      style={{ marginBottom: '20px' }}
                    >
                      total price: {parseInt(data.total_price).toLocaleString()}
                    </span>
                  </>
                );
              })}
            </>
          ) : (
            <></>
          )}
        </ModalBox>
      </Modal>
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  min-height: 100%;

  .title_wrap {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    margin-bottom: 50px;

    &-span {
      font-size: 28px;
      font-weight: 600;
      user-select: none;
    }
  }
`;

const ModalBox = styled.div`
  width: 500px;
  height: 600px;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  padding: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;

  .title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .subtitle {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .content {
    margin-bottom: 5px;
    word-spacing: 3px;
  }
`;
