import { useEffect } from 'react';
import Component from '../../components/order';
import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/reducer';
import {
  handleOrderListRequest,
  handleOrderUpdateRequest,
} from '../../redux/action/admin.action';

export default function Order() {
  const { addToast } = useToasts();

  const dispatch = useDispatch();
  const { order_list } = useSelector(({ admin }: RootState) => admin);

  const handleUpdateStatus = (id: string, value: string) => {
    function success() {
      addToast('success update status', { appearance: 'success' });

      function success() {}
      function failure() {}

      dispatch(handleOrderListRequest(success, failure));
    }
    function failure() {}

    dispatch(handleOrderUpdateRequest({ id, status: value }, success, failure));
  };

  useEffect(() => {
    function success() {}
    function failure() {}

    dispatch(handleOrderListRequest(success, failure));
  }, []);

  return (
    <Component
      order_list={order_list}
      handleUpdateStatus={handleUpdateStatus}
    />
  );
}
