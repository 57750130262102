import { useState } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';

type MainProps = {
  user_list: any[];
  handleUpdateLevel: (id: string, value: string) => void;
  handleUpdateType: (id: string, value: string) => void;
  handleDelete: (id: string) => void;
};

export default function Main({
  user_list,
  handleUpdateLevel,
  handleUpdateType,
  handleDelete,
}: MainProps) {
  const [isModal, setIsModal] = useState(false);
  const [detail, setDetail] = useState<any>(null);

  const handleModal = (data: any) => {
    setIsModal(true);
    setDetail(data);
  };

  return (
    <Box>
      <div className={cn('title_wrap')}>
        <span className={cn('title_wrap-span')}>User</span>
      </div>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell align='center'>ID</TableCell>
              <TableCell align='center'>Email</TableCell>
              <TableCell align='center'>Name</TableCell>
              <TableCell align='center'>Country</TableCell>
              <TableCell align='center'>Customer Type</TableCell>
              <TableCell align='center'>Access Level</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {user_list.map((user: any, index: number) => (
              <TableRow
                key={index}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  cursor: 'pointer',
                }}
              >
                <TableCell
                  component='th'
                  scope='row'
                  align='center'
                  onClick={() => handleModal(user)}
                >
                  {index + 1}
                </TableCell>
                <TableCell align='center' onClick={() => handleModal(user)}>
                  {user.email}
                </TableCell>
                <TableCell align='center' onClick={() => handleModal(user)}>
                  {user.name}
                </TableCell>
                <TableCell align='center' onClick={() => handleModal(user)}>
                  {user.country}
                </TableCell>
                <TableCell align='center'>
                  <FormControl style={{ width: '200px' }}>
                    <InputLabel id='demo-simple-select-label'>
                      Customer Type
                    </InputLabel>
                    <Select
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      defaultValue={user.customer_type}
                      label='Access Level'
                      onChange={(e) =>
                        handleUpdateType(user.id, e.target.value)
                      }
                    >
                      <MenuItem value={'general'}>General</MenuItem>
                      <MenuItem value={'special'}>Special</MenuItem>
                      <MenuItem value={'wholesale'}>Wholesale</MenuItem>
                      <MenuItem value={'salon'}>Salon</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell align='center'>
                  <FormControl style={{ width: '230px' }}>
                    <InputLabel id='demo-simple-select-label'>
                      Access Level
                    </InputLabel>
                    <Select
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      defaultValue={user.access_level}
                      label='Access Level'
                      onChange={(e) =>
                        handleUpdateLevel(user.id, e.target.value)
                      }
                    >
                      <MenuItem value={'waiting'}>Waiting</MenuItem>
                      <MenuItem value={'allow_individual'}>
                        Allow Individual
                      </MenuItem>
                      <MenuItem value={'allow_firm'}>Allow Firm</MenuItem>
                      <MenuItem value={'individual'}>Individual</MenuItem>
                      <MenuItem value={'corporate'}>Corporate</MenuItem>
                      <MenuItem value={'admin'}>Admin</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={isModal}
        onClose={() => {
          setIsModal(false);
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <ModalBox>
          {detail ? (
            <>
              <span className={cn('title')}>User Info</span>
              <span className={cn('content')}>name: {detail.name}</span>
              <span className={cn('content')}>
                address:{' '}
                {`${detail.address_list[0].address} ${detail.address_list[0].detail_address}`}
              </span>
              <span className={cn('content')}>email: {detail.email}</span>
              <span className={cn('content')}>mobile: {detail.mobile}</span>
              <span className={cn('content')}>job: {detail.job}</span>
              <span className={cn('content')}>countury: {detail.country}</span>
              <span
                className={cn('content')}
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => {
                  if (detail.image) window.open(detail.image, '_blank');
                }}
              >
                image: {detail.image ? 'image link' : 'not image'}
              </span>
              <span className={cn('content')}>
                access level: {detail.access_level}
              </span>
              <span className={cn('content')}>
                created at: {dayjs(detail.created_at).format('YYYY-MM-DD')}
              </span>

              <Button
                variant='contained'
                color='error'
                onClick={() => {
                  handleDelete(detail.id);
                  setIsModal(false);
                }}
                size='medium'
                style={{ marginTop: '30px' }}
              >
                Delete user
              </Button>
            </>
          ) : (
            <></>
          )}
        </ModalBox>
      </Modal>
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  min-height: 100%;

  .title_wrap {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    margin-bottom: 50px;

    &-span {
      font-size: 28px;
      font-weight: 600;
      user-select: none;
    }
  }
`;

const ModalBox = styled.div`
  width: 500px;
  height: 600px;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  padding: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;

  .title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .content {
    margin-bottom: 5px;
    word-spacing: 3px;
    line-height: 30px;
  }
`;
