import { createAction } from 'redux-actions';

import { Payload } from '../reducer/admin.reducer';

export const SIGN_IN_REQUEST = 'admin/SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'admin/SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'admin/SIGN_IN_FAILURE';
export const handleSignInRequest = createAction(
  SIGN_IN_REQUEST,
  (data: any, success: Payload['success'], failure: Payload['failure']) => ({
    data,
    success,
    failure,
  })
);

export const USER_LIST_REQUEST = 'admin/USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'admin/USER_LIST_SUCCESS';
export const USER_LIST_FAILURE = 'admin/USER_LIST_FAILURE';
export const handleUserListRequest = createAction(
  USER_LIST_REQUEST,
  (success: Payload['success'], failure: Payload['failure']) => ({
    success,
    failure,
  })
);

export const USER_UPDATE_REQUEST = 'admin/USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'admin/USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAILURE = 'admin/USER_UPDATE_FAILURE';
export const handleUserUpdateRequest = createAction(
  USER_UPDATE_REQUEST,
  (
    data: Payload['data'],
    success: Payload['success'],
    failure: Payload['failure']
  ) => ({
    data,
    success,
    failure,
  })
);

export const USER_DELETE_REQUEST = 'admin/USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'admin/USER_DELETE_SUCCESS';
export const USER_DELETE_FAILURE = 'admin/USER_DELETE_FAILURE';
export const handlUserDeleteRequest = createAction(
  USER_DELETE_REQUEST,
  (
    data: Payload['data'],
    success: Payload['success'],
    failure: Payload['failure']
  ) => ({
    data,
    success,
    failure,
  })
);

export const CATEGORY_LIST_REQUEST = 'admin/CATEGORY_LIST_REQUEST';
export const CATEGORY_LIST_SUCCESS = 'admin/CATEGORY_LIST_SUCCESS';
export const CATEGORY_LIST_FAILURE = 'admin/CATEGORY_LIST_FAILURE';
export const handleCategoryListRequest = createAction(
  CATEGORY_LIST_REQUEST,
  (success: Payload['success'], failure: Payload['failure']) => ({
    success,
    failure,
  })
);

export const CATEGORY_CREATE_REQUEST = 'admin/CATEGORY_CREATE_REQUEST';
export const CATEGORY_CREATE_SUCCESS = 'admin/CATEGORY_CREATE_SUCCESS';
export const CATEGORY_CREATE_FAILURE = 'admin/CATEGORY_CREATE_FAILURE';
export const handleCategoryCreateRequest = createAction(
  CATEGORY_CREATE_REQUEST,
  (
    data: Payload['data'],
    success: Payload['success'],
    failure: Payload['failure']
  ) => ({
    data,
    success,
    failure,
  })
);

export const CATEGORY_UPDATE_REQUEST = 'admin/CATEGORY_UPDATE_REQUEST';
export const CATEGORY_UPDATE_SUCCESS = 'admin/CATEGORY_UPDATE_SUCCESS';
export const CATEGORY_UPDATE_FAILURE = 'admin/CATEGORY_UPDATE_FAILURE';
export const handleCategoryUpdateRequest = createAction(
  CATEGORY_UPDATE_REQUEST,
  (
    data: Payload['data'],
    success: Payload['success'],
    failure: Payload['failure']
  ) => ({
    data,
    success,
    failure,
  })
);

export const NOTICE_REQUEST = 'admin/NOTICE_REQUEST';
export const NOTICE_SUCCESS = 'admin/NOTICE_SUCCESS';
export const NOTICE_FAILURE = 'admin/NOTICE_FAILURE';
export const handleNoticeRequest = createAction(
  NOTICE_REQUEST,
  (success: Payload['success'], failure: Payload['failure']) => ({
    success,
    failure,
  })
);

export const NOTICE_UPDATE_REQUEST = 'admin/NOTICE_UPDATE_REQUEST';
export const NOTICE_UPDATE_SUCCESS = 'admin/NOTICE_UPDATE_SUCCESS';
export const NOTICE_UPDATE_FAILURE = 'admin/NOTICE_UPDATE_FAILURE';
export const handleNoticeUpdateRequest = createAction(
  NOTICE_UPDATE_REQUEST,
  (
    data: Payload['data'],
    success: Payload['success'],
    failure: Payload['failure']
  ) => ({
    data,
    success,
    failure,
  })
);

export const BANNER_LIST_REQUEST = 'admin/BANNER_LIST_REQUEST';
export const BANNER_LIST_SUCCESS = 'admin/BANNER_LIST_SUCCESS';
export const BANNER_LIST_FAILURE = 'admin/BANNER_LIST_FAILURE';
export const handleBannerListRequest = createAction(
  BANNER_LIST_REQUEST,
  (success: Payload['success'], failure: Payload['failure']) => ({
    success,
    failure,
  })
);

export const BANNER_DELETE_REQUEST = 'admin/BANNER_DELETE_REQUEST';
export const BANNER_DELETE_SUCCESS = 'admin/BANNER_DELETE_SUCCESS';
export const BANNER_DELETE_FAILURE = 'admin/BANNER_DELETE_FAILURE';
export const handleBannerDeleteRequest = createAction(
  BANNER_DELETE_REQUEST,
  (
    data: Payload['data'],
    success: Payload['success'],
    failure: Payload['failure']
  ) => ({
    data,
    success,
    failure,
  })
);

export const BANNER_CREATE_REQUEST = 'admin/BANNER_CREATE_REQUEST';
export const BANNER_CREATE_SUCCESS = 'admin/BANNER_CREATE_SUCCESS';
export const BANNER_CREATE_FAILURE = 'admin/BANNER_CREATE_FAILURE';
export const handleBannerCreateRequest = createAction(
  BANNER_CREATE_REQUEST,
  (
    data: Payload['data'],
    success: Payload['success'],
    failure: Payload['failure']
  ) => ({
    data,
    success,
    failure,
  })
);

export const PRODUCT_CREATE_REQUEST = 'admin/PRODUCT_CREATE_REQUEST';
export const PRODUCT_CREATE_SUCCESS = 'admin/PRODUCT_CREATE_SUCCESS';
export const PRODUCT_CREATE_FAILURE = 'admin/PRODUCT_CREATE_FAILURE';
export const handleProductCreateRequest = createAction(
  PRODUCT_CREATE_REQUEST,
  (
    data: Payload['data'],
    success: Payload['success'],
    failure: Payload['failure']
  ) => ({
    data,
    success,
    failure,
  })
);

export const PRODUCT_UPDATE_REQUEST = 'admin/PRODUCT_UPDATE_REQUEST';
export const PRODUCT_UPDATE_SUCCESS = 'admin/PRODUCT_UPDATE_SUCCESS';
export const PRODUCT_UPDATE_FAILURE = 'admin/PRODUCT_UPDATE_FAILURE';
export const handleProductUpdateRequest = createAction(
  PRODUCT_UPDATE_REQUEST,
  (
    data: Payload['data'],
    success: Payload['success'],
    failure: Payload['failure']
  ) => ({
    data,
    success,
    failure,
  })
);

export const PRODUCT_DELETE_REQUEST = 'admin/PRODUCT_DELETE_REQUEST';
export const PRODUCT_DELETE_SUCCESS = 'admin/PRODUCT_DELETE_SUCCESS';
export const PRODUCT_DELETE_FAILURE = 'admin/PRODUCT_DELETE_FAILURE';
export const handleProductDeleteRequest = createAction(
  PRODUCT_DELETE_REQUEST,
  (
    data: Payload['data'],
    success: Payload['success'],
    failure: Payload['failure']
  ) => ({
    data,
    success,
    failure,
  })
);

export const PRODUCT_LIST_REQUEST = 'admin/PRODUCT_LIST_REQUEST';
export const PRODUCT_LIST_SUCCESS = 'admin/PRODUCT_LIST_SUCCESS';
export const PRODUCT_LIST_FAILURE = 'admin/PRODUCT_LIST_FAILURE';
export const handleProductListRequest = createAction(
  PRODUCT_LIST_REQUEST,
  (success: Payload['success'], failure: Payload['failure']) => ({
    success,
    failure,
  })
);

export const ORDER_LIST_REQUEST = 'admin/ORDER_LIST_REQUEST';
export const ORDER_LIST_SUCCESS = 'admin/ORDER_LIST_SUCCESS';
export const ORDER_LIST_FAILURE = 'admin/ORDER_LIST_FAILURE';
export const handleOrderListRequest = createAction(
  ORDER_LIST_REQUEST,
  (success: Payload['success'], failure: Payload['failure']) => ({
    success,
    failure,
  })
);

export const ORDER_UPDATE_REQUEST = 'admin/ORDER_UPDATE_REQUEST';
export const ORDER_UPDATE_SUCCESS = 'admin/ORDER_UPDATE_SUCCESS';
export const ORDER_UPDATE_FAILURE = 'admin/ORDER_UPDATE_FAILURE';
export const handleOrderUpdateRequest = createAction(
  ORDER_UPDATE_REQUEST,
  (
    data: Payload['data'],
    success: Payload['success'],
    failure: Payload['failure']
  ) => ({
    data,
    success,
    failure,
  })
);

export const INQUIRY_REQUEST = 'admin/INQUIRY_REQUEST';
export const INQUIRY_SUCCESS = 'admin/INQUIRY_SUCCESS';
export const INQUIRY_FAILURE = 'admin/INQUIRY_FAILURE';
export const handleInquiryRequest = createAction(
  INQUIRY_REQUEST,
  (success: Payload['success'], failure: Payload['failure']) => ({
    success,
    failure,
  })
);
